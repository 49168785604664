<template>
  <div
    class="main"
    v-loading="loading"
    element-loading-text="上传中，请等待..."
  >
    <heads></heads>
    <ctdkbanners></ctdkbanners>
    <islogin />
    <div class="content">
      <div class="cons">
        <div class="cons-title">智能选刊</div>
        <shuoming :smdata="smdata" :srcList="srcList"></shuoming>
        <!-- <div class="cons-title-subtitle">
          <p>
            必填内容：标题、摘要、关键词。选填内容：作者单位、论文正文。一键选刊：轻松提交，智能评估；领域关联，刊文整合；自动匹配，投稿直达
          </p>
        </div> -->
        <div class="lunt-xzfs">
          <div class="lunt-xzfs-list lunt-xzfs-list-active">选中文刊</div>
          <div class="lunt-xzfs-list lunt-xzfs-list-none" @click="clickywxk">
            Selected English publications
          </div>
          <div class="lunt-xzfs-list lunt-xzfs-list-none" @click="clickjbxk">
            简版选刊
          </div>
        </div>
        <div class="cons-form">
          <el-form
            :model="form"
            label-width="120px"
            :rules="baseRules"
            ref="ruleFormRef"
          >
            <div class="yxzc" v-if="linkzhicheng != ''">
              已选职称：{{ linkzhicheng }}
            </div>
            <div class="custom-block">
              标题、摘要、关键词为必填信息，用于推荐相关期刊。
            </div>
            <el-form-item label="标题" required prop="btword">
              <el-input
                v-model="form.btword"
                placeholder="请输入标题（5-100个字符）"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="摘要" required prop="zyword">
              <el-input
                v-model="form.zyword"
                type="textarea"
                placeholder="请输入摘要（50-2000个字符，摘要低于150字符可能导致推荐结果不精确）"
                @keyup.ctrl.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="关键词" required prop="kwword">
              <el-input
                v-model="form.kwword"
                placeholder="请输入关键词，中间以逗号或空格或分号隔开（5-100个字符）"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="custom-block">以下为选填信息，用于提高推荐效果。</div>
            <!-- <el-form-item label="职称">
              <div class="zhicheng">
                <div class="zhicheng-list">
                  <el-select
                    v-model="diquvalue"
                    class="m-2"
                    placeholder="请选择地区"
                    @change="changediqu"
                  >
                    <el-option
                      v-for="(item, key) in diqulist"
                      :key="key"
                      :label="item.gxname"
                      :value="item.Id"
                    />
                  </el-select>
                </div>
                <div class="zhicheng-fgx" v-show="xiala2.length > 0">-</div>
                <div class="zhicheng-list" v-show="xiala2.length > 0">
                  
                  <el-select
                    v-model="xiala2value"
                    class="m-2"
                    placeholder="请选择职称"
                    @change="changexiala2"
                  >
                    <el-option
                      v-for="(item, key) in xiala2"
                      :key="key"
                      :label="item.gxname"
                      :value="item.Id"
                    />
                  </el-select>
                </div>
                <div class="zhicheng-fgx" v-show="xiala3.length > 0">-</div>
                <div class="zhicheng-list" v-show="xiala3.length > 0">
                  
                  <el-select
                    v-model="xiala3value"
                    class="m-2"
                    placeholder="请选择专业"
                    @change="changexiala3"
                  >
                    <el-option
                      v-for="(item, key) in xiala3"
                      :key="key"
                      :label="item.gxname"
                      :value="item.Id"
                    />
                  </el-select>
                </div>
                <div class="zhicheng-fgx" v-show="xiala4.length > 0">-</div>
                <div class="zhicheng-list" v-show="xiala4.length > 0">
                  
                  <el-select
                    v-model="xiala4value"
                    class="m-2"
                    placeholder="请选择级别"
                    @change="changexiala4"
                  >
                    <el-option
                      v-for="(item, key) in xiala4"
                      :key="key"
                      :label="item.gxname"
                      :value="item.Id"
                    />
                  </el-select>
                </div>
                <div class="zhicheng-fgx" v-show="xiala5.length > 0">-</div>
                <div class="zhicheng-list" v-show="xiala5.length > 0">
                  
                  <el-select
                    v-model="xiala5value"
                    class="m-2"
                    placeholder="请选择单位"
                    @change="changexiala5"
                  >
                    <el-option
                      v-for="(item, key) in xiala5"
                      :key="key"
                      :label="item.gxname"
                      :value="item.Id"
                    />
                  </el-select>
                </div>
              </div>
            </el-form-item> -->
            <div class="zhicheng-tips" v-if="iszhicheng">
              <div class="zhicheng-tips-title">
                <span>选择职称：</span>{{ xuanzezhicheng }}
              </div>
              <div
                class="zhicheng-tips-title"
                v-if="zhichengxiangqing.pkmark != ''"
              >
                <span>学术论文：</span>{{ zhichengxiangqing.pkmark }}篇
              </div>
              <div
                class="zhicheng-tips-title"
                v-if="zhichengxiangqing.hxmark != ''"
              >
                <span>科普文章：</span>{{ zhichengxiangqing.hxmark }}篇
              </div>
              <div class="zhicheng-tips-con">
                <div
                  class="zhicheng-tips-con-title"
                  v-bind="zhichengxiangqing.lysource != ''"
                >
                  职称要求<a :href="zhichengxiangqing.lysource" target="_blank"
                    >查看来源</a
                  >
                </div>
                <div
                  class="zhicheng-tips-con-nr"
                  v-if="zhichengxiangqing.yaoqiu != ''"
                >
                  <el-input
                    v-model="zhichengxiangqing.yaoqiu"
                    type="textarea"
                    autosize
                    disabled
                  />
                </div>
              </div>
              <div
                class="zhicheng-tips-con"
                v-if="zhichengxiangqing.remark != ''"
              >
                <div class="zhicheng-tips-con-title">职称说明</div>
                <div class="zhicheng-tips-con-nr">
                  <el-input
                    v-model="zhichengxiangqing.remark"
                    type="textarea"
                    autosize
                    disabled
                  />
                </div>
              </div>
            </div>
            <el-form-item label="作者单位">
              <el-input
                v-model="form.writerorg"
                placeholder="请输入作者单位"
                @keyup.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <el-form-item label="正文">
              <el-input
                v-model="form.zwword"
                placeholder="请输入正文"
                type="textarea"
                @keyup.ctrl.enter="onSubmit(ruleFormRef)"
              />
            </el-form-item>
            <div class="con-btn">
              <el-button type="primary" @click="onSubmit(ruleFormRef)"
                >立即提交</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
// 图片
let smdata = reactive([
  { url: "/img/shuoming/03-01.png", tips: "提交信息" },
  { url: "/img/shuoming/03-02.png", tips: "得到结果" },
]);
let srcList = reactive([
  "/img/shuoming/03-01.png",
  "/img/shuoming/03-02.png",
]);
// loading
let loading = ref(false);
let sbzczyval = ref("");
let form = reactive({
  btword: "",
  zyword: "",
  kwword: "",
  zwword: "",
  ckwx: "",
  writer: "",
  writerorg: "",
  jjkt: "",
  diqu: "",
  fbyt: "",
  sbzczy: "", // 需要提交的名称（全部名称，逗号隔开）
  sbzcjb: "", // 需要提交的id（最后一级的id）
  subxk: "",
});

// 是否显示职称tips
let iszhicheng = ref(false);
// 职称地区
let diqulist = reactive([]);
let diquvalue = ref("");
let diquname = ref("");
// 选中地区id
let diquid = ref(0);
//获取到的全部职称
let zhichengyuan = reactive([]);
// 二级下拉框
let xiala2 = reactive([]);
let xiala2value = ref("");
let xiala2name = ref("");
// 三级下拉框
let xiala3 = reactive([]);
let xiala3value = ref("");
let xiala3name = ref("");
// 四级下拉框
let xiala4 = reactive([]);
let xiala4value = ref("");
let xiala4name = ref("");
// 五级下拉框
let xiala5 = reactive([]);
let xiala5value = ref("");
let xiala5name = ref("");
// 选择的职称
let xuanzezhicheng = ref("");
// 职称详情
let zhichengxiangqing = reactive({});
// 链接中的职称
let linkzhicheng = ref("");
// 网页标题
let wangyetitle=ref('')

const ruleFormRef = ref();

const baseRules = {
  btword: [{ required: true, message: "请输入标题", trigger: "blur" }],
  zyword: [{ required: true, message: "请输入摘要", trigger: "blur" }],
  kwword: [{ required: true, message: "请输入关键词", trigger: "blur" }],
};

// 获取详情
const getxq=()=>{
  get("/journalTitle/GetDet/"+router.currentRoute.value.query.id, {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    // console.log(res)
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", wangyetitle.value+';'+res.data.remark+';'+res.data.yaoqiu);
    //Object.assign(zhichengxiangqing,res.data)
  });
}

// 获取链接职称
const getlinkzhicheng = () => {
  if (router.currentRoute.value.query.qikanlist) {
    let zhichengname = JSON.parse(
      decodeURIComponent(router.currentRoute.value.query.qikanlist)
    );
    // console.log(zhichengname)
    // 页面标题
    let yemiantitle=''
    // 页面关键字
    let yemiankeywords=''
    zhichengname.forEach((e, i) => {
      yemiantitle+=e
      if (i < zhichengname.length - 1) {
        linkzhicheng.value += e + "-";
        yemiankeywords+=e+'；'
      } else {
        linkzhicheng.value += e;
        yemiankeywords+=e
      }
    });
    wangyetitle.value=yemiantitle+'职称申报论文要求';
    document.title = wangyetitle.value
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content",'职称申报；论文发表；'+ yemiankeywords);

    // 获取详情
    getxq()
  }
};
getlinkzhicheng();




// 获取地区
const getarea = () => {
  get("/journalTitle/GetProvinceList").then((res) => {
    // console.log(res);
    diqulist.length = 0;
    diqulist.push(...res.data);
  });
};
getarea();

// 选择了地区
const changediqu = (val) => {
  diquid.value = val;
  diquname.value = diqulist.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala2value.value = "";
  xiala2.length = 0;
  xiala3value.value = "";
  xiala3.length = 0;
  xiala4value.value = "";
  xiala4.length = 0;
  xiala5value.value = "";
  xiala5.length = 0;
  getzhicheng();
};

// 获取地区下的全部职称
const getzhicheng = () => {
  get("/journalTitle/GetProvinceNextALLList/"+diquid.value).then((res) => {
    // console.log(res)
    zhichengyuan.length = 0;
    zhichengyuan.push(...res.data);
    xuanranerji();
  });
};

// 渲染二级菜单
const xuanranerji = () => {
  xiala2.length = 0;
  xiala2.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == diquid.value;
    })
  );
};
// 选择二级菜单
const changexiala2 = (val) => {
  xiala2name.value = xiala2.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala3value.value = "";
  xiala3.length = 0;
  xiala4value.value = "";
  xiala4.length = 0;
  xiala5value.value = "";
  xiala5.length = 0;
  xiala3.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == val;
    })
  );
};
// 选择3级菜单
const changexiala3 = (val) => {
  xiala3name.value = xiala3.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala4value.value = "";
  xiala4.length = 0;
  xiala5value.value = "";
  xiala5.length = 0;
  xiala4.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == val;
    })
  );
  // 没有四级菜单
  if (xiala4.length == 0) {
    // 保存id
    form.sbzcjb = val;
    // 拼接名称
    let sbzczytemp = [];
    sbzczytemp.push(diquname.value);
    sbzczytemp.push(xiala2name.value);
    sbzczytemp.push(xiala3name.value);
    form.sbzczy = JSON.stringify(sbzczytemp);
    // 获取详情
    getxiangqing();
  }
};
// 选择4级菜单
const changexiala4 = (val) => {
  xiala4name.value = xiala4.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala5value.value = "";
  xiala5.length = 0;
  xiala5.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == val;
    })
  );
  // 没有5级菜单
  if (xiala5.length == 0) {
    // 保存id
    form.sbzcjb = val;
    // 拼接名称
    let sbzczytemp = [];
    sbzczytemp.push(diquname.value);
    sbzczytemp.push(xiala2name.value);
    sbzczytemp.push(xiala3name.value);
    sbzczytemp.push(xiala4name.value);
    form.sbzczy = JSON.stringify(sbzczytemp);
    // 获取详情
    getxiangqing();
  }
};
// 选择5级菜单
const changexiala5 = (val) => {
  xiala5name.value = xiala5.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 保存id
  form.sbzcjb = val;
  // 拼接名称
  let sbzczytemp = [];
  sbzczytemp.push(diquname.value);
  sbzczytemp.push(xiala2name.value);
  sbzczytemp.push(xiala3name.value);
  sbzczytemp.push(xiala4name.value);
  sbzczytemp.push(xiala5name.value);
  form.sbzczy = JSON.stringify(sbzczytemp);
  // 获取详情
  getxiangqing();
};

// 获取详情
const getxiangqing = () => {
  xuanzezhicheng.value = "";
  // 选择的职称 xuanzezhicheng
  JSON.parse(form.sbzczy).forEach((e, i) => {
    if (i < JSON.parse(form.sbzczy).length - 1) {
      xuanzezhicheng.value += e + "-";
    } else {
      xuanzezhicheng.value += e;
    }
  });
  get("/journalTitle/GetDet/"+form.sbzcjb).then((res) => {
    iszhicheng.value = true;
    Object.assign(zhichengxiangqing, res.data);
  });
};

// 选英文刊
const clickywxk = () => {
  router.push({
    path: "/xkzsen/sub",
    query: {},
  });
};

// 简版选刊
const clickjbxk=()=>{
  router.push({
    path: "/jbtg/sbu",
    query: {},
  });
}

// 提交代码
const onSubmit = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    // 通过验证规则 true
    if (valid) {
      // 是否选择了职称
      let zhichengid = router.currentRoute.value.query.id;
      let zhichengname = router.currentRoute.value.query.qikanlist; //需要decodeURIComponent解码
      // console.log(zhichengid)
      if (zhichengid) {
        form.sbzcjb = zhichengid;
        form.sbzczy = decodeURIComponent(zhichengname);
      }
      // console.log(form)

      loading.value = true;
      post("/journalRecommend/SubNewFive", form)
        .then((res) => {
          loading.value = false;
          if (res.code === 200) {
            ElMessage({
              message: "提交成功！",
              duration: 1000,
              onClose: () => {
                router.push({
                  path: "/xkzs/res",
                  query: {
                    id: res.data,
                  },
                });
              },
            });
          } else {
            ElMessage({
              message: "提交失败：" + res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.value = false;
        });
    }
  });
};
</script>

<style scoped>
:deep(.el-form-item__label) {
  background: #f5f7fa;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
}
:deep(.cons-form .el-input__inner) {
  height: 40px;
  font-size: 18px;
}
:deep(.cons-form .el-textarea__inner) {
  font-size: 18px;
}
:deep(.cons-form .el-button) {
  width: 180px;
  height: 50px;
  font-size: 18px;
}
:deep(.zhicheng-tips-con-nr .el-textarea__inner) {
  font-size: 14px;
  color: #777;
}

.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 30px 0;
}
.cons-form {
  width: 100%;
}
.cons-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.cons-subtitle {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  background: #f5f7fa;
  font-size: 14px;
  line-height: 20px;
  color: #999;
  margin: auto;
  margin-bottom: 30px;
}
.custom-block {
  padding: 10px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 5px solid #409eff;
  margin: 20px 0 20px 0;
  font-size: 18px;
}
.con-btn {
  text-align: center;
}
.cons-title-subtitle {
  color: #999;
  font-size: 14px;
}
.cons-title-subtitle p {
  margin: 0;
  padding: 0;
  text-align: center;
}

.lunt-xzfs {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}
.lunt-xzfs-list {
  width: 250px;
  height: 40px;
  border: 1px solid #409eff;
  box-sizing: border-box;
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.lunt-xzfs-list-none {
  cursor: pointer;
  background: #fff;
  color: #409eff;
}
.lunt-xzfs-list-none:hover {
  background: #eef7ff;
}
.lunt-xzfs-list-active {
  background: #409eff;
  color: #fff;
  cursor: default;
}

.zhicheng {
  width: 100%;
}
.zhicheng:after {
  content: "";
  display: table;
  clear: both;
}
.zhicheng-list {
  width: 200px;
  float: left;
}
.zhicheng-fgx {
  width: 20px;
  height: 40px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zhicheng-tips {
  background: #f5f7fa;
  padding: 20px 20px 10px 20px;
  font-size: 14px;
  margin-bottom: 20px;
  color: #777;
}
.zhicheng-tips-title {
  padding-bottom: 10px;
}
.zhicheng-tips-title > span {
  font-weight: bold;
}
.zhicheng-tips-con {
  margin-bottom: 10px;
}
.zhicheng-tips-con-title {
  font-weight: bold;
  padding-bottom: 5px;
}
.zhicheng-tips-con-title a {
  margin-left: 10px;
  font-weight: 500;
}
.zhicheng-tips-con-title a:link,
.zhicheng-tips-con-title a:visited {
  text-decoration: none;
  color: #409eff;
}
.zhicheng-tips-con-title a:hover {
  text-decoration: underline;
}

.yxzc {
  margin-top: 20px;
}
</style>
